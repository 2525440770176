const Shape = () => {
  const shapeStyle = {
    transform: "scaleX(-1) scaleY(-1)",
    position: "absolute",
    top: "-100px",
    left: 0,
  };

  return (
    <svg
      style={shapeStyle}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 300 130"
      preserveAspectRatio="none"
    >
      <g fill="url(#divider-pix_section_demos-top-overlay-layer-3)">
        <polygon points="205.6875 18.8837729 300 3.92225477e-14 300 49.83 205.6875 68.67"></polygon>
        <path d="M43.5002561,84.4287699 L43.5002561,67.6974063 L0,76.0518732 L0,93.1053435 L43.5002561,84.4287699 Z M43.1248125,68.1469741 L43.1248125,84.5056196 L0,93.1036023 L0,76.426513 L43.1248125,68.1469741 Z"></path>
      </g>
      <g fill="#ffffff">
        <polygon points="0 109.769452 300 49.8270893 300 130 0 130"></polygon>
      </g>
      <g
        fill="url(#divider-pix_section_demos-top-overlay-layer-1)"
        transform="translate(0.0, 49.83)"
      >
        <polygon points="87.319394 25.82088 87.375 42.5187032 71.812 45.62088 71.8127561 62.3221129 0 77.0678507 0 76.6446398 71.4373125 62.0149568 71.437 45.69588 0 59.9394524 0 43.2679827"></polygon>
        <polygon points="256.3125 8.72 300 2.1931479e-17 300 16.5748991 256.3125 25.2852738"></polygon>
      </g>
      <defs>
        <linearGradient
          id="divider-pix_section_demos-top-overlay-layer-3"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#e9ecef"></stop>
          <stop offset="100%" stopColor="#f8f9fa"></stop>
        </linearGradient>
        <linearGradient
          id="divider-pix_section_demos-top-overlay-layer-1"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#ffffff"></stop>
          <stop offset="100%" stopColor="#f8f9fa"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Shape;
