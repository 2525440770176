const BlackDivider = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 1200 366"
      preserveAspectRatio="none"
    >
      <polygon fill="#2c2c38" points="0 360 1200 120 1200 366 0 366"></polygon>
      <defs></defs>
    </svg>
  );
};

export default BlackDivider;
