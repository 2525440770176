const OvalDivider = () => {
  return (
    <svg
      className="flex"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 1200 116"
      preserveAspectRatio="none"
    >
      <path
        fill="#f8f9fa"
        d="M0,110 C200,76.6666667 400,60 600,60 C800,60 1000,76.6666667 1200,110 L1200,116 L0,116 L0,110 Z"
      ></path>
      <defs></defs>
    </svg>
  );
};

export default OvalDivider;
