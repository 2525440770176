import HeaderBuilder from "../../assets/img/header-builder.png";

import FooterBuilder from "../../assets/img/footer-builder.png";

import PageBuilder from "../../assets/img/page-builder.png";

import DomainChecker from "../../assets/img/domain-checker.png";

import WhmcsSync from "../../assets/img/whmcs-sync.png";

import WhmcsTemplate from "../../assets/img/whmcs-template.png";

import ElementorWidgets from "../../assets/img/elementor-widgets.png";

import AdminPanel from "../../assets/img/admin-panel.png";

import { THEME_DOCUMENTATION_URL } from "../../constants/constants";

import classes from "./Packs.module.css";

const Packs = () => {
  return (
    <div className={classes.packs}>
      <div className="container">
        <h1>All in One Theme</h1>
        <h2>The most powerful features packed in one robust theme.</h2>
        <div className={classes.featuresList}>
          {/* Item */}
          <div className={classes.item}>
            <a
              href={`${THEME_DOCUMENTATION_URL}/creating-website-header/`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={HeaderBuilder}
                alt="Header Builder"
                width="115"
                height="115"
              />
            </a>
          </div>
          {/* Item */}
          <div className={classes.item}>
            <a
              href={`${THEME_DOCUMENTATION_URL}/creating-website-footer/`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={FooterBuilder}
                alt="Footer Builder"
                width="115"
                height="115"
              />
            </a>
          </div>
          {/* Item */}
          <div className={classes.item}>
            <a
              href={`${THEME_DOCUMENTATION_URL}/all-you-need-to-know-about-elementor/`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={PageBuilder}
                alt="Page Builder"
                width="115"
                height="115"
              />
            </a>
          </div>
          {/* Item */}
          <div className={classes.item}>
            <a
              href={`${THEME_DOCUMENTATION_URL}/domain-search/`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={DomainChecker}
                alt="Domain Checker"
                width="115"
                height="115"
              />
            </a>
          </div>
          {/* Item */}
          <div className={classes.item}>
            <a
              href={`${THEME_DOCUMENTATION_URL}/domain-search/`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={WhmcsSync} alt="WHMCS Sync" width="115" height="115" />
            </a>
          </div>
          {/* Item */}
          <div className={classes.item}>
            <a
              href={`${THEME_DOCUMENTATION_URL}/how-to-install-whmcs-template/`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={WhmcsTemplate}
                alt="WHMCS Template"
                width="115"
                height="115"
              />
            </a>
          </div>
          {/* Item */}
          <div className={classes.item}>
            <a
              href={`${THEME_DOCUMENTATION_URL}/category/elementor/`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={ElementorWidgets}
                alt="Elementor Widgets"
                width="115"
                height="115"
              />
            </a>
          </div>
          {/* Item */}
          <div className={classes.item}>
            <a
              href={`${THEME_DOCUMENTATION_URL}/category/options/`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={AdminPanel}
                alt="Admin Panel"
                width="115"
                height="115"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packs;
