const Elementor = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="Elementor-logo"
      width="163"
      height="27"
      viewBox="0 0 163 27"
      fill="#92033B"
    >
      <path
        d="M102.054 12.939C102.054 12.939 100.65 13.2733 99.465 13.5566L97.6605 13.9676C97.6546 13.9676 97.6467 13.9676 97.6447 13.9676C97.6447 13.4818 97.6801 12.9685 97.7961 12.4965C97.9455 11.8907 98.2738 11.1827 98.8419 10.8719C99.4611 10.5336 100.216 10.4943 100.872 10.7559C101.551 11.0253 101.861 11.6803 101.995 12.3588C102.032 12.5476 102.058 12.7384 102.075 12.9311L102.054 12.939ZM106.756 13.9656C106.756 9.25916 103.792 7.23535 100.006 7.23535C95.7242 7.23535 93.043 10.2012 93.043 13.9892C93.043 18.1077 95.3252 20.7903 100.241 20.7903C102.899 20.7903 104.405 20.3203 106.192 19.4254L105.51 16.3415C104.145 16.9532 102.876 17.3288 101.181 17.3288C99.3235 17.3288 98.2639 16.6227 97.8649 15.305H106.638C106.709 14.953 106.756 14.5537 106.756 13.9656Z"
        fill="#92033B"
      ></path>
      <path
        d="M62.9249 12.939C62.9249 12.939 61.5214 13.2733 60.3361 13.5566L58.5316 13.9676C58.5257 13.9676 58.5178 13.9676 58.5158 13.9676C58.5158 13.4818 58.5512 12.9685 58.6672 12.4965C58.8166 11.8907 59.1449 11.1827 59.7129 10.8719C60.3322 10.5336 61.087 10.4943 61.7435 10.7559C62.4217 11.0253 62.7323 11.6803 62.866 12.3588C62.9033 12.5476 62.9289 12.7384 62.9466 12.9311L62.9249 12.939ZM67.627 13.9656C67.627 9.25916 64.6626 7.23535 60.8767 7.23535C56.5953 7.23535 53.9141 10.2012 53.9141 13.9892C53.9141 18.1077 56.1963 20.7903 61.1125 20.7903C63.7702 20.7903 65.2759 20.3203 67.0628 19.4254L66.3807 16.3415C65.0165 16.9532 63.7466 17.3288 62.0522 17.3288C60.1946 17.3288 59.135 16.6227 58.736 15.305H67.509C67.5798 14.953 67.627 14.5537 67.627 13.9656Z"
        fill="#92033B"
      ></path>
      <path
        d="M52.311 3.77588H47.9668V20.3204H52.311V3.77588Z"
        fill="#92033B"
      ></path>
      <path
        d="M107.674 7.65838H112.238L113.198 10.583C113.799 9.13936 115.151 7.28076 117.55 7.28076C120.842 7.28076 122.631 8.95055 122.631 13.2578V20.3185H118.069C118.069 18.8474 118.071 17.3762 118.072 15.9051C118.072 15.2305 118.061 14.5559 118.071 13.8813C118.078 13.2578 118.122 12.6147 117.791 12.0561C117.567 11.6785 117.2 11.4012 116.803 11.2123C115.997 10.8269 115.124 10.8387 114.334 11.2497C114.139 11.35 113.199 11.8594 113.199 12.0954V20.3185H108.637V11.0747L107.674 7.65838Z"
        fill="#92033B"
      ></path>
      <path
        d="M126.324 10.9755H124.23V7.65759H126.324V5.58265L130.886 4.50879V7.65759H135.472V10.9755H130.886V14.6947C130.886 16.1541 131.592 16.8365 132.65 16.8365C133.731 16.8365 134.344 16.6949 135.26 16.3901L135.801 19.826C134.554 20.3669 133.002 20.6265 131.425 20.6265C128.109 20.6265 126.32 19.0491 126.32 15.9908V10.9755H126.324Z"
        fill="#92033B"
      ></path>
      <path
        d="M144.252 17.026C145.921 17.026 146.91 15.8262 146.91 13.8949C146.91 11.9635 145.968 10.8582 144.323 10.8582C142.652 10.8582 141.689 11.9635 141.689 13.9657C141.689 15.8479 142.631 17.026 144.252 17.026ZM144.299 7.16455C148.581 7.16455 151.708 9.84723 151.708 14.0129C151.708 18.2021 148.581 20.7196 144.252 20.7196C139.947 20.7196 136.889 18.1313 136.889 14.0129C136.891 9.84723 139.926 7.16455 144.299 7.16455Z"
        fill="#92033B"
      ></path>
      <path
        d="M88.9542 7.61891C88.1463 7.28652 87.2322 7.16262 86.3614 7.28849C85.9172 7.35339 85.4827 7.48123 85.0798 7.67988C83.975 8.22664 83.1121 9.47161 82.6482 10.5828C82.3454 9.30443 81.4589 8.1519 80.1655 7.61891C79.3575 7.28652 78.4435 7.16262 77.5727 7.28849C77.1284 7.35339 76.694 7.48123 76.291 7.67988C75.1882 8.22467 74.3272 9.46571 73.8633 10.573V10.4924L72.9335 7.65824H68.3711L69.3323 11.0745V20.3203H73.8653V12.0599C73.881 11.9989 74.0835 11.8848 74.1189 11.8573C74.6496 11.4797 75.2747 11.0903 75.9391 11.0411C76.6173 10.99 77.2857 11.3361 77.6906 11.875C77.7339 11.934 77.7751 11.993 77.8125 12.0559C78.1447 12.6145 78.0995 13.2576 78.0916 13.8811C78.0837 14.5557 78.0955 15.2303 78.0936 15.9049C78.0916 17.3761 78.0896 18.8472 78.0896 20.3184H82.6541V13.2576C82.6541 13.2222 82.6541 13.1868 82.6541 13.1514V12.0618C82.6639 12.0028 82.8723 11.8829 82.9096 11.8573C83.4404 11.4797 84.0655 11.0903 84.7299 11.0411C85.408 10.99 86.0764 11.3361 86.4813 11.875C86.5246 11.934 86.5659 11.993 86.6032 12.0559C86.9354 12.6145 86.8902 13.2576 86.8843 13.8811C86.8764 14.5557 86.8882 15.2303 86.8863 15.9049C86.8843 17.3761 86.8823 18.8472 86.8823 20.3184H91.4448V13.2576C91.4428 11.1886 91.146 8.52166 88.9542 7.61891Z"
        fill="#92033B"
      ></path>
      <path
        d="M162.503 7.28271C160.105 7.28271 158.752 9.14131 158.151 10.5849L157.189 7.66033H152.625L153.586 11.0766V20.3224H158.151V11.7788C158.801 11.6647 162.33 12.3137 163 12.5596V7.29845C162.837 7.28861 162.672 7.28271 162.503 7.28271Z"
        fill="#92033B"
      ></path>
      <path
        d="M41.6632 12.5161C41.6632 12.5161 40.2597 12.8505 39.0744 13.1337L37.2698 13.5448C37.2639 13.5448 37.2561 13.5448 37.2541 13.5448C37.2541 13.059 37.2895 12.5456 37.4055 12.0736C37.5548 11.4679 37.8831 10.7598 38.4512 10.4491C39.0704 10.1108 39.8253 10.0714 40.4818 10.333C41.16 10.6025 41.4706 11.2574 41.6042 11.9359C41.6416 12.1248 41.6671 12.3155 41.6848 12.5083L41.6632 12.5161ZM46.3672 13.5428C46.3672 8.83631 43.4029 6.8125 39.6169 6.8125C35.3355 6.8125 32.6543 9.77839 32.6543 13.5664C32.6543 17.6848 34.9365 20.3675 39.8528 20.3675C42.5104 20.3675 44.0162 19.8974 45.803 19.0026L45.1209 15.9187C43.7567 16.5303 42.4868 16.906 40.7924 16.906C38.9348 16.906 37.8753 16.1999 37.4762 14.8822H46.2492C46.32 14.5321 46.3672 14.1309 46.3672 13.5428Z"
        fill="#92033B"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13.2084C0 20.4107 5.83624 26.2501 13.0347 26.2501C20.2332 26.2501 26.0695 20.4107 26.0695 13.2084C26.0695 6.00609 20.2332 0.166748 13.0347 0.166748C5.83624 0.166748 0 6.00609 0 13.2084ZM9.77554 7.77424H7.60342V18.6426H9.77554V7.77424ZM11.9477 7.77424H18.4641V9.94753H11.9477V7.77424ZM18.4641 12.1208H11.9477V14.2941H18.4641V12.1208ZM11.9477 16.4693H18.4641V18.6426H11.9477V16.4693Z"
        fill="#92033B"
      ></path>
    </svg>
  );
};

export default Elementor;
