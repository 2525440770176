import { useEffect, useState } from "react";

import Elementor from "../../assets/svg/Elementor";

import Shape from "../../assets/svg/Shape";

import { THEME_DEMOS_URL } from "../../constants/constants";

import WHMCSLogo from "../../assets/img/whmcs-logo.png";

import NavBg from "../../assets/svg/NavBg";

import phoxWHMCS from "../../assets/img/phox-whmcs.webp";

import BlackDivider from "../../assets/svg/BlackDivider";

import classes from "./Demos.module.css";

const Demos = () => {
  // Demos List
  const [demosList, setDemosList] = useState([]);

  // Fetch Demos
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`../../../demos.json`);
      const newData = await response.json();
      if (response.ok) {
        setDemosList(newData?.demos);
      }
    };

    fetchData();
  }, []);

  // Count
  const [demosCount, setDemosCount] = useState();

  // Fetch Count
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`../../../info.json`);
      const newData = await response.json();
      if (response.ok) {
        setDemosCount(newData?.count);
      }
    };

    fetchData();
  }, []);

  return (
    <div id="demos" className={classes.demos}>
      <Shape />

      <div className={classes.headings}>
        <h1 className="text-gradient">{demosCount}+</h1>
        <h2>
          World-Class <br /> Stunning Demos
        </h2>
        <h3>A design for the new decade!</h3>

        <Elementor />
      </div>

      <div className="container">
        <ul className={classes.demosItems}>
          {demosList.map((item, i) => (
            <li key={i}>
              <a
                className="demo-ga"
                target="_blank"
                rel="noreferrer"
                href={`${THEME_DEMOS_URL}/${item.link}`}
              >
                <div className={classes.thumbWrap}>
                  <img
                    src={`../../../demos/${item.thumb}`}
                    alt={item.name}
                    width="299"
                    height="273"
                  />
                </div>
                <h4>{item.name}</h4>
              </a>
            </li>
          ))}
        </ul>

        {/* WHMCS Demo */}
        <div className={classes.whmcsDemo}>
          <NavBg className={classes.navBgCustom} />
          <div className={classes.info}>
            <img
              className={classes.logo}
              src={WHMCSLogo}
              alt="WHMCS"
              width="165"
              height="40"
            />
            <h1>
              Highly Customizable <br /> WHMCS Template
            </h1>
            <a
              className="primary-btn"
              target="_blank"
              rel="noreferrer"
              href="https://preview.whmcsdes.com/index.php?systpl=phox&amp;carttpl=phox"
            >
              Preview
            </a>
          </div>
          <div className={classes.imgWrap}>
            <img src={phoxWHMCS} alt="Phox" with="558" height="450" />
          </div>
        </div>
      </div>

      <BlackDivider className={classes.blackDivider} />
    </div>
  );
};

export default Demos;
