const NavBg = ({ className }) => {
  // Nav Bg Style
  const navBgStyle = {
    position: "absolute",
    left: 0,
    top: "-50px",
    zIndex: -1,
    transform: "scaleX(-1) scaleY(-1)",
  };

  return (
    <svg
      className={className}
      style={navBgStyle}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 300 53"
      preserveAspectRatio="none"
    >
      <g
        className="pix-waiting pix-animate animated"
        data-anim-type="fade-in-up"
        data-anim-delay="600"
      >
        <g fill="url(#divider-85746-top-overlay-layer-3)">
          <path d="M150,11.3355932 C150,31.9290847 133.2105,48.6237288 112.5,48.6237288 L112.5,11.3355932 L150,11.3355932 Z M37.5,11.3150205 L37.5,48.603156 L0,48.603156 C0,28.2155994 16.4552052,11.6493032 36.8798687,11.3200161 L37.5,11.3150205 Z M281.12069,11.1864407 C291.372387,11.1864407 299.702599,19.367651 299.868178,29.5221973 L299.87069,29.8305085 C299.87069,40.0242868 291.642995,48.3074349 281.430753,48.4720785 L281.12069,48.4745763 C270.868992,48.4745763 262.538781,40.2933659 262.373202,30.1388196 L262.37069,29.8305085 C262.37069,19.5337627 270.76544,11.1864407 281.12069,11.1864407 Z M225.12931,0 C231.280552,0 236.27846,4.90850692 236.377803,11.0014474 L236.37931,11.1864407 C236.37931,17.3029292 231.442914,22.272601 225.315355,22.3713827 L225.12931,22.3728814 C218.915935,22.3728814 213.87931,17.3647119 213.87931,11.1864407 C213.87931,5.0699522 218.815707,0.1002804 224.943266,0.00149861278 L225.12931,0 Z"></path>
        </g>
      </g>
      <g
        className="pix-waiting pix-animate animated"
        data-anim-type="fade-in-up"
        data-anim-delay="300"
      >
        <g fill="url(#divider-85746-top-overlay-layer-2)">
          <path d="M37.5,11.3150205 L37.8100629,11.3175222 C47.9201831,11.4807785 56.0852477,19.6136304 56.2475379,29.6830079 L56.25,29.9887271 L56.25,48.4745763 C45.9983025,48.4745763 37.6680911,40.2807262 37.502512,30.1096819 L37.5,29.8008696 L37.5,11.3150205 Z M262.5,11.3150205 L262.5,29.8010573 C262.5,40.1140684 254.10525,48.4745763 243.75,48.4745763 L243.75,29.9885394 C243.75,19.7786585 251.977694,11.4824259 262.189937,11.3175222 L262.5,11.3150205 Z M206.155211,29.7726476 C216.458735,29.7726476 224.831059,37.9538579 224.997475,48.1084042 L225,48.4167154 L206.344789,48.4167154 C195.936812,48.4167154 187.5,40.0693933 187.5,29.7726476 L206.155211,29.7726476 Z"></path>
        </g>
      </g>
      <g fill="url(#divider-85746-top-overlay-layer-1)">
        <path d="M37.5,29.8008696 C37.5,40.1143578 45.89475,48.4745763 56.25,48.4745763 L56.25,29.9887271 C56.25,19.7792445 48.0231129,11.4832416 37.8115661,11.3175466 L37.5,11.3150205 C58.003395,11.3150205 74.6638179,27.6774411 74.994976,47.9865338 L75,48.603156 L111.625844,48.613797 C91.5247389,48.1565995 75.3314558,31.9719042 75.0050239,11.9522155 L75,11.3355932 L93.36225,11.3355289 L93.3646328,11.6297184 C93.5319253,21.8899182 101.865018,30.1759308 112.183514,30.3422782 L112.5,30.3448276 L112.5,48.6126645 L187.5,48.6320544 L187.5,48.603156 C166.7895,48.603156 150,31.908512 150,11.3150205 L187.5,11.3150205 C207.798361,11.3150205 224.330225,27.3518289 224.980155,47.3784574 L224.994938,47.9864103 L224.98687,47.7142226 C224.617284,37.8436817 216.491691,29.9338521 206.462775,29.7750806 L206.155211,29.7726476 L187.5,29.7726476 C187.5,39.9664259 195.76892,48.249574 206.033148,48.4142176 L206.344789,48.4167154 L224.9985,48.4167154 L225,48.5953255 L225.005024,47.9865338 C225.334502,27.7805059 241.827929,11.4811944 262.188146,11.316283 C252.079,11.4815704 243.914736,19.6140539 243.752462,29.6828383 L243.75,29.9885394 L243.75,48.4745763 C254.001698,48.4745763 262.331909,40.2804425 262.497488,30.1098555 L262.5,29.8118001 L262.5,48.603156 L262.49925,48.6518171 L300,48.6610169 L300,53 L0,53 L0,48.6610169 L37.5,48.603156 L37.5,29.8008696 Z"></path>
      </g>
      <defs>
        <linearGradient
          id="divider-85746-top-overlay-layer-3"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="rgba(230,230,237,0.25)"></stop>
          <stop offset="100%" stopColor="#f8f9fa"></stop>
        </linearGradient>
        <linearGradient
          id="divider-85746-top-overlay-layer-2"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#ffffff"></stop>
          <stop offset="100%" stopColor="rgba(230,230,237,0.3)"></stop>
        </linearGradient>
        <linearGradient
          id="divider-85746-top-overlay-layer-1"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="rgba(233,236,239,0.55)"></stop>
          <stop offset="100%" stopColor="rgba(255,255,255,0.01)"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NavBg;
