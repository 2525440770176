import NavBg from "../../assets/svg/NavBg";

import Demos from "../../components/Demos/Demos";

import Features from "../../components/Features/Features";

import Footer from "../../components/Footer/Footer";

import Hero from "../../components/Hero/Hero";

import Nav from "../../components/Nav/Nav";

import Packs from "../../components/Packs/Packs";

import Testimonials from "../../components/Testimonials/Testimonials";

const Home = () => {
  return (
    <>
      <NavBg />

      <Nav />

      <Hero />

      <Packs />

      <Demos />

      <Features />

      <Testimonials />

      <Footer />
    </>
  );
};

export default Home;
