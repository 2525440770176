import FooterDivider from "../../assets/svg/FooterDivider";

import Logo from "../../assets/img/footer-logo.png";

import { THEME_PURCHASE_URL } from "../../constants/constants";

import Divider from "../../assets/svg/Divider";

import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <FooterDivider className={classes.footerDivider} />
      <div className={classes.wrapElements}>
        <div className="container">
          <a
            className={classes.logoImg}
            href={THEME_PURCHASE_URL}
            target="_blank"
            rel="noreferrer"
          >
            <img src={Logo} alt="Phox" width="155" height="45" />
          </a>

          <h1>
            Build stunning and powerful{" "}
            <span>
              websites <Divider />
            </span>{" "}
            <br />
            without writing a single line of code!
          </h1>

          <p>
            This stunning preview page was made entirely using Phox theme!{" "}
            <br />
            Purchase Phox today and start creating beautiful websites!
          </p>

          <a
            className={`primary-btn ${classes.btn}`}
            target="_blank"
            rel="noreferrer"
            href={THEME_PURCHASE_URL}
          >
            Purchase Phox
          </a>
        </div>
      </div>

      <hr />

      <h2>
        Phox by{" "}
        <a target="_blank" rel="noreferrer" href="https://whmcsdes.com">
          WHMCSdes
        </a>{" "}
        © All rights reserved
      </h2>
    </footer>
  );
};

export default Footer;
