import { useState, useEffect } from "react";

import Logo from "../../assets/img/logo.png";

import {
  THEME_DOCUMENTATION_URL,
  THEME_PURCHASE_URL,
} from "../../constants/constants";

import classes from "./Nav.module.css";

const Nav = () => {
  // Active Menu
  const [activeMenu, setActiveMenu] = useState(false);

  // Sticky State
  const [isSticky, setIsSticky] = useState(false);

  // Handle Sticky Nav
  useEffect(() => {
    window.onscroll = function (e) {
      if (window.scrollY > 250) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
  });

  return (
    <nav className={`${classes.nav} ${isSticky ? classes.sticky : ""}`}>
      <div className={`container flex align-center ${classes.mainWrapper}`}>
        {/* Logo */}
        <a className="flex" href="/">
          <img src={Logo} alt="Phox" width="155" height="45" />
        </a>

        {/* Mobile Trigger */}
        <button
          onClick={() => setActiveMenu(!activeMenu)}
          className={classes.mobileTrigger}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>

        {/* Menu */}
        <ul
          className={`ml-auto flex align-center ${
            activeMenu ? classes.active : ""
          }`}
        >
          <li>
            <a href="#demos">Demos</a>
          </li>
          <li>
            <a href="#features">Features</a>
          </li>
          <li>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://whmcsdes.com/ca/submitticket.php?step=2&amp;deptid=2"
            >
              Support
            </a>
          </li>
          <li>
            <a rel="noreferrer" target="_blank" href={THEME_DOCUMENTATION_URL}>
              Documentation
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://whmcsdes.com/services"
            >
              Customization
            </a>
          </li>
        </ul>

        {/* Purchase */}
        <a
          className={`primary-btn ${classes.btn} ${
            activeMenu ? classes.active : ""
          }`}
          rel="noreferrer"
          target="_blank"
          href={THEME_PURCHASE_URL}
        >
          Purchase Phox
        </a>
      </div>
    </nav>
  );
};

export default Nav;
