import { useEffect, useState } from "react";

import { THEME_PURCHASE_URL } from "../../constants/constants";

import Envato from "../../assets/img/envato.png";

import classes from "./Testimonials.module.css";

const Testimonials = () => {
  // Stars
  const fullStars = Array.from(Array(5).keys());

  // Testimonials List
  const [testimonialsList, setTestimonialsList] = useState([]);

  // Fetch Testimonials
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`../../../testimonials.json`);
      const newData = await response.json();
      if (response.ok) {
        setTestimonialsList(newData?.list);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={classes.testimonials}>
      <div
        style={{ justifyContent: "space-between", alignItems: "flex-start" }}
        className={`container flex ${classes.wrapElements}`}
      >
        <div className={classes.headings}>
          <h1>Rated 4.8 of 5</h1>
          <h2>
            What our <br /> customers say <br /> about Phox?
          </h2>
          <a className="primary-btn" href={THEME_PURCHASE_URL}>
            Purchase Phox
          </a>
          <h3>5-Star Rated Theme</h3>
          <div className={classes.rate}>
            {fullStars.map((star, i) => (
              <svg
                key={i}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#FFAB1B"
                className="bi bi-star-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg>
            ))}
          </div>
        </div>

        <div className={classes.testimonialsList}>
          {testimonialsList.map((item, i) => (
            <div key={i} className={classes.block}>
              <img src={Envato} alt="Envato" height="70" width="70" />
              <p>{item.comment}</p>
              <div className={classes.rating}>
                {fullStars.map((star, i) => (
                  <svg
                    key={i}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#FFAB1B"
                    className="bi bi-star-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                ))}
              </div>
              <h4>{item.author}</h4>
              <h5>Envato Market User</h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
