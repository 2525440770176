const FooterDivider = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 1200 130"
      preserveAspectRatio="none"
    >
      <path
        fill="#f8f9fa"
        d="M0,130 L0,60 C200,93.3333333 400,110 600,110 C800,110 1000,93.3333333 1200,60 L1200,130 L0,130 Z"
      ></path>
      <defs></defs>
    </svg>
  );
};

export default FooterDivider;
