import Divider from "../../assets/svg/Divider";

import DomainChecker from "../../assets/img/domain.png";

import GoDaddyReseller from "../../assets/img/godaddy.webp";

import OvalDivider from "../../assets/svg/OvalDivider";

import classes from "./Features.module.css";

const Features = () => {
  return (
    <div id="features" className={classes.features}>
      <h1>
        Heavy on{" "}
        <span>
          features. <Divider />
        </span>
        <br /> Packed full of updates.
      </h1>
      <div className="container">
        <div className={`flex ${classes.featuresWrap}`}>
          {/* Block */}
          <div className={classes.block}>
            <div className={classes.content}>
              <h2 style={{ backgroundColor: "#878DEC" }}>
                Advanced Domain Checker
              </h2>
              <h3>Domain Checker</h3>
              <p>
                Check domain name availability from your WordPress site. You can
                check or search for any generic top-level domains (gTLD)
              </p>
            </div>
            <img
              src={DomainChecker}
              alt="Domain Checker"
              width="543"
              height="221"
            />
          </div>
          {/* Block */}
          <div className={classes.block}>
            <div className={classes.content}>
              <h2 style={{ backgroundColor: "#03C64C" }}>
                GoDaddy Reseller Store
              </h2>
              <h3>GoDaddy Reseller Store</h3>
              <p>
                Godaddy Reseller Store Comptability with Gutenbergy Widgets, and
                GoDaddy Products.
              </p>
            </div>
            <img
              src={GoDaddyReseller}
              alt="GoDaddy Reseller Store"
              width="543"
              height="221"
            />
          </div>
        </div>
      </div>

      <OvalDivider />
    </div>
  );
};

export default Features;
