import { useEffect, useState } from "react";

import Divider from "../../assets/svg/Divider";

import { THEME_PURCHASE_URL } from "../../constants/constants";

import classes from "./Hero.module.css";

const Hero = () => {
  // Count
  const [demosCount, setDemosCount] = useState();

  // Fetch Count
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`../../../info.json`);
      const newData = await response.json();
      if (response.ok) {
        setDemosCount(newData?.count);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={classes.hero}>
      <div className={`container ${classes.wrapContent}`}>
        <h1>
          The Next{" "}
          <span>
            Generation <Divider />
          </span>{" "}
          <br /> Hosting Theme
        </h1>
        <h2>
          Build &amp; customize stunning pages in minutes visually,
          highly-customizable WHMCS templates with {demosCount}+ unique and
          interactive WordPress layouts.{" "}
        </h2>
        <a className={`primary-btn ${classes.btn}`} href={THEME_PURCHASE_URL}>
          Get Started
        </a>
        <div className="flex align-center gap-60">
          <div className={classes.block}>
            <h1>1K+</h1>
            <h2>Website Built</h2>
          </div>
          <div className={classes.block}>
            <h1>{demosCount}+</h1>
            <h2>Demos</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
